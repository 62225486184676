import React from 'react';

const Card = ({ url, cover, alt }) => (
  <div className="card">
    <a target="_blank" rel="noopener noreferrer" href={url}>
      <img src={cover} alt={alt} />
    </a>
  </div>
)


export default Card;
