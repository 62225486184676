import React, { Component } from 'react'
import Layout from '../components/_shared/Layout';
import Card from '../components/elements/Card';
import Container from '../components/elements/Container';

export default class Books extends Component {
  render() {
    const books = [
      {
        alt: 'rich dad poor dad',
        cover: '/books/rich_dad_poor_dad.jpeg',
        url: 'https://www.amazon.com/Rich-Dad-Poor-Robert-Kiyosaki/dp/0739413937'
      },
      {
        alt: 'MONEY Master the Game: 7 Simple Steps to Financial Freedom',
        cover: '/books/MONEY_Master_the_Game.jpg',
        url: 'https://www.amazon.com/MONEY-Master-Game-Financial-Freedom/dp/1476757801'
      },
      {
        alt: 'The Millionaire Fastlane: Crack the Code to Wealth and Live Rich for a Lifetime',
        cover: '/books/the_millionaire_fastlane.jpg',
        url: 'https://www.amazon.com/Millionaire-Fastlane-Crack-Wealth-Lifetime/dp/0984358102'
      },
      {
        alt: 'F.U. Money: Make As Much Money As You Damn Well Want And Live Your LIfe As YOu Damn Well Please',
        cover: '/books/fu_money.jpg',
        url: 'https://www.amazon.com/F-U-Money-Make-Much-Please/dp/1599325748'
      },
      {
        alt: 'How to Win Friends & Influence People',
        cover: '/books/how_to_win_friends.jpg',
        url: 'https://www.amazon.com/How-Win-Friends-Influence-People/dp/0671027034'
      },
      {
        alt: 'The Miracle Morning: The Not-So-Obvious Secret Guaranteed to Transform Your Life (Before 8AM)',
        cover: '/books/the_miracle_morning.jpg',
        url: 'https://www.amazon.com/Miracle-Morning-Not-So-Obvious-Guaranteed-Transform/dp/0979019710'
      },
      {
        alt: 'Think and Grow Rich',
        cover: '/books/think_and_grow_rich.jpg',
        url: 'https://www.amazon.com/Think-Grow-Rich-Napoleon-Hill/dp/0785833528'
      },
      {
        alt: 'Make Book',
        cover: '/books/make.jpg',
        url: 'https://makebook.io/'
      }
    ];
    return (
      <Layout>
        <Container>
          {books.map((book) => (
            <Card
              alt={book.alt}
              cover={book.cover}
              url={book.url}
            />
          ))}
        </Container>
      </Layout>
    )
  }
}
